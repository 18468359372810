import { Component } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';
import { AsyncPipe, NgClass } from '@angular/common';
import { FormlySelectModule } from '@ngx-formly/core/select';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { TranslocoDirective } from '@jsverse/transloco';
import { AutoCompleteSelectTypeComponent } from '~ngx-shared/formly/auto-complete-select-type/auto-complete-select-type.component';
import { DatePipe } from '~ngx-shared/pipes';

@Component({
  selector: 'app-formly-person-search-select-type',
  imports: [
    AutoCompleteModule,
    ReactiveFormsModule,
    FormlyModule,
    NgClass,
    FormlySelectModule,
    AsyncPipe,
    ButtonModule,
    TranslocoDirective,
    DatePipe
  ],
  templateUrl: './formly-person-search-select-type.component.html',
  styleUrl: './formly-person-search-select-type.component.scss'
})
export class FormlyPersonSearchSelectTypeComponent extends AutoCompleteSelectTypeComponent {}
