import { inject, Injectable } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { filter, map, Observable, tap } from 'rxjs';
import { Apollo, gql } from 'apollo-angular';
import { TranslocoService } from '@jsverse/transloco';
import { ObjectUtils } from 'primeng/utils';
import { FormlyUtil } from '~ngx-shared/formly';
import { TenantService } from '~madrasa/services';
import {
  AcademyOrganisationModel,
  AcademyRoomModel,
  AcademySchoolModel,
  AcademyTeacherHourlyRateModel,
  CoreCountryEnum,
  CoreEmailTypeEnum,
  CorePhoneTypeEnum,
  PrintPaperTemplateModel
} from '../models';
import { FormlySchoolTypeComponent } from '../core/components/formly-school-type/formly-school-type.component';
import { ModelUtil } from '~madrasa/utils';
import { FormlyPersonSearchSelectTypeComponent } from '~madrasa/core/components/formly-person-search-select-type/formly-person-search-select-type.component';
import { FormlyPersonSearchTypeComponent } from '~madrasa/core/components/formly-person-search-type/formly-person-search-type.component';
import {
  FileUploadFieldConfig,
  FormlyFileUploadTypeComponent
} from '~madrasa/core/components/formly-file-upload-type/formly-file-upload-type.component';
import { PersonUtil } from '~madrasa/core/utils/person.util';

@Injectable({ providedIn: 'root' })
export class FormlyService {
  private apollo = inject(Apollo);
  private tenantService = inject(TenantService);
  private translocoService = inject(TranslocoService);

  createTenantFieldConfig(params?: {
    key?: string;
    required?: boolean;
    label?: string;
  }): FormlyFieldConfig {
    return FormlyUtil.createRow([
      FormlyUtil.createSelectField(params?.key || 'tenant_id', {
        defaultValue: this.tenantService.currentTenant()?.id,
        props: {
          label: params?.label || 'tenant',
          required: params ? params.required : true,
          options: this.tenantService.tenants$.pipe(
            map(
              tenants =>
                tenants?.map(tenant => ({
                  label: tenant.name,
                  value: tenant.id
                })) || []
            )
          )
        }
      })
    ]);
  }

  createTeacherHourlyRateFieldConfig(params?: {
    key?: string;
    required?: boolean;
    label?: string;
  }): FormlyFieldConfig {
    return FormlyUtil.createSelectField(params?.key || 'teacher_hourly_rate_id', {
      props: {
        label: params?.label || 'teacher_hourly_rate',
        required: params ? params.required : false,
        disabled: true,
        showClear: true,
        options: this.apollo
          .query<{
            result: AcademyTeacherHourlyRateModel[];
          }>({
            query: gql`
              query ReadAcademyTeacherHourlyRates {
                result: academy_teacher_hourly_rate(order_by: { name: asc }) {
                  id
                  name
                }
              }
            `
          })
          .pipe(
            map(queryResult =>
              queryResult.data.result.map(teacher_hourly_rate => ({
                label: teacher_hourly_rate.name,
                value: teacher_hourly_rate.id
              }))
            )
          )
      }
    });
  }

  createCoursePriceFieldConfig(params: { key: string }): FormlyFieldConfig {
    return FormlyUtil.createGroup('course_prices', [
      {
        key: params.key,
        fieldGroup: [
          {
            key: 'id'
          },
          FormlyUtil.createRow([
            FormlyUtil.createNumberField('single_price', {
              props: {
                label: 'course_price.single_price',
                maxFractionDigits: 2,
                iconPre: 'pi pi-euro',
                min: 0
              }
            })
          ]),
          FormlyUtil.createRow([
            FormlyUtil.createNumberField('dual_price', {
              props: {
                label: 'course_price.dual_price',
                maxFractionDigits: 2,
                iconPre: 'pi pi-euro',
                min: 0
              }
            })
          ]),
          FormlyUtil.createRow([
            FormlyUtil.createNumberField('three_or_more_price', {
              props: {
                label: 'course_price.three_or_more_price',
                maxFractionDigits: 2,
                iconPre: 'pi pi-euro',
                min: 0
              }
            })
          ])
        ]
      }
    ]);
  }

  createPersonFieldConfig(params?: {
    key?: string;
    required?: boolean;
    label?: string;
    readonly?: boolean;
    forceSelection?: boolean;
    excludePersonIds?: number[];
    conditions?:
      | { [key: string]: any }[]
      | ((field: FormlyFieldConfig) => { [key: string]: any }[]);
    showSchool?: boolean;
  }): FormlyFieldConfig {
    return FormlyUtil.createRow([
      {
        ...FormlyUtil.createAutoCompleteSelectField(params?.key || 'person_id', {
          props: {
            label: params?.label || 'person',
            forceSelection: params?.forceSelection || false,
            required: params ? params.required : true,
            readonly: params ? params.readonly : false,
            showSchool: params?.showSchool,
            options: [],
            completeMethod: (event, field) => {
              let conditions: any[] = [];
              if (params?.conditions) {
                if (ObjectUtils.isFunction(params.conditions)) {
                  // @ts-ignore
                  conditions = params.conditions(field);
                } else if (Array.isArray(params.conditions)) {
                  conditions = params.conditions;
                }
              }

              conditions.push({
                person_id: { _nin: params?.excludePersonIds || [] }
              });

              // event.query is the search string
              // Split the search string into words
              const searchWords = event.query.split(' ');
              // Create a condition for each word
              searchWords.forEach(word => {
                // Is Word a number?
                const isNumber = /^\d+$/.test(word);
                if (isNumber) {
                  conditions.push({
                    _or: [{ person_id: { _eq: Number(word) } }]
                  });
                } else {
                  conditions.push({
                    _or: [{ first_name: { _iregex: word } }, { last_name: { _iregex: word } }]
                  });
                }
              });

              PersonUtil.queryCurrentPersonData(this.apollo, {
                query: params?.showSchool ? 'school_students_active { school { name } }' : '',
                variables: {
                  where: {
                    _and: conditions
                  }
                }
              }).subscribe(queryResult => {
                field.props.options = queryResult.data?.result.map(personData => {
                  const full_name = ModelUtil.getFullName(personData, this.translocoService);
                  return {
                    label: full_name,
                    value: {
                      ...personData,
                      full_name
                    }
                  };
                });
              });
            }
          }
        }),
        type: FormlyPersonSearchTypeComponent,
        wrappers: ['field-wrapper']
      }
    ]);
  }

  createPersonSelectFieldConfig(params?: {
    key?: string;
    required?: boolean;
    label?: string;
    readonly?: boolean;
    excludePersonIds?: number[];
    click?: (event: MouseEvent, field: FormlyFieldConfig) => void;
    conditions?:
      | { [key: string]: any }[]
      | ((field: FormlyFieldConfig) => { [key: string]: any }[]);
  }): FormlyFieldConfig {
    return FormlyUtil.createRow([
      {
        ...FormlyUtil.createAutoCompleteSelectField(params?.key || 'person_id', {
          props: {
            label: params?.label || 'person',
            required: params ? params.required : true,
            readonly: params ? params.readonly : false,
            options: [],
            clickMethod: params?.click,
            completeMethod: (event, field) => {
              let conditions: any[] = [];
              if (params?.conditions) {
                if (ObjectUtils.isFunction(params.conditions)) {
                  // @ts-ignore
                  conditions = params.conditions(field);
                } else if (Array.isArray(params.conditions)) {
                  conditions = params.conditions;
                }
              }

              // event.query is the search string
              // Split the search string into words
              const searchWords = event.query.split(' ');
              // Create a condition for each word
              searchWords.forEach(word => {
                conditions.push(
                  {
                    _or: [{ first_name: { _iregex: word } }, { last_name: { _iregex: word } }]
                  },
                  {
                    person_id: { _nin: params?.excludePersonIds || [] }
                  }
                );
              });

              PersonUtil.queryCurrentPersonData(this.apollo, {
                variables: {
                  where: {
                    _and: conditions
                  }
                }
              }).subscribe(queryResult => {
                field.props.options = queryResult.data?.result.map(personData => {
                  const full_name = ModelUtil.getFullName(personData, this.translocoService);
                  return {
                    label: full_name,
                    value: {
                      ...personData,
                      full_name
                    }
                  };
                });
              });
            }
          }
        }),
        type: FormlyPersonSearchSelectTypeComponent,
        wrappers: ['field-wrapper']
      }
    ]);
  }

  createOrganisationFieldConfig(params?: {
    key?: string;
    required?: boolean;
    label?: string;
    readonly?: boolean;
  }): FormlyFieldConfig {
    return FormlyUtil.createRow([
      FormlyUtil.createSelectField(params?.key || 'organisation_id', {
        props: {
          label: params?.label || 'organisation',
          required: params ? params.required : true,
          readonly: params ? params.readonly : false,
          options: this.apollo
            .query<{
              result: AcademyOrganisationModel[];
            }>({
              query: gql`
                query ReadAcademyOrganisations {
                  result: academy_organisation(order_by: { name: asc }) {
                    id
                    name
                  }
                }
              `
            })
            .pipe(
              map(queryResult =>
                queryResult.data.result.map(item => ({
                  label: item.name,
                  value: item.id
                }))
              )
            )
        }
      })
    ]);
  }

  createOrganisationsFieldConfig(params?: {
    key?: string;
    required?: boolean;
    label?: string;
    readonly?: boolean;
  }): FormlyFieldConfig {
    return FormlyUtil.createRow([
      FormlyUtil.createMultiSelectField(params?.key || 'organisations', {
        props: {
          label: params?.label || 'organisation',
          required: params?.required != undefined ? params.required : true,
          readonly: params ? params.readonly : false,
          options: this.apollo
            .query<{
              result: AcademyOrganisationModel[];
            }>({
              query: gql`
                query ReadAcademyOrganisations {
                  result: academy_organisation(order_by: { name: asc }) {
                    id
                    name
                  }
                }
              `
            })
            .pipe(
              map(queryResult =>
                queryResult.data.result.map(item => ({
                  label: item.name,
                  value: item.id
                }))
              )
            )
        }
      })
    ]);
  }

  createRoomFieldConfig(params?: {
    key?: string;
    required?: boolean;
    label?: string;
    readonly?: boolean;
  }): FormlyFieldConfig {
    return FormlyUtil.createRow([
      FormlyUtil.createSelectField(params?.key || 'room_id', {
        props: {
          label: params?.label || 'room',
          required: params?.required != undefined ? params.required : false,
          readonly: params?.readonly != undefined ? params.readonly : false,
          options: []
        },
        hooks: {
          onInit: (field: FormlyFieldConfig) => {
            const createRequest = (schoolId?: any) => {
              return this.apollo
                .query<{
                  result: AcademyRoomModel[];
                }>({
                  query: gql`
                    query ReadAcademyRooms($where: academy_room_bool_exp = {}) {
                      result: academy_room(order_by: { name: asc }, where: $where) {
                        id
                        name
                        area
                        seating
                      }
                    }
                  `,
                  variables: {
                    where: { school_id: { _eq: schoolId } }
                  }
                })
                .pipe(
                  map(queryResult =>
                    queryResult.data.result.map(item => ({
                      label: item.name,
                      value: item
                    }))
                  )
                );
            };

            if (field.props && field.model.school_id) {
              field.props.options = createRequest(field.model.school_id);
            }

            return field.options?.fieldChanges?.pipe(
              filter(event => event.type === 'valueChanges' && event.field.key === 'school_id'),
              tap(event => {
                field.formControl?.setValue(undefined);

                if (event.value && field.props) {
                  field.props.options = createRequest(event.value);
                }
              })
            );
          }
        }
      })
    ]);
  }

  createSchoolFieldConfig(params?: {
    key?: string;
    required?: boolean;
    label?: string;
    readonly?: boolean;
    initOrganisationFunction?: (field: FormlyFieldConfig) => boolean | undefined;
    initVariablesFunction?: (field: FormlyFieldConfig) => { [key: string]: any };
    fieldChangesKey?: string;
    fieldChangesVariablesFunction?: (field: FormlyFieldConfig) => { [key: string]: any };
  }): FormlyFieldConfig {
    return FormlyUtil.createRow([
      FormlyUtil.createSelectField(params?.key || 'school_id', {
        props: {
          label: params?.label || 'school',
          required: params?.required != undefined ? params.required : true,
          readonly: params?.readonly != undefined ? params.readonly : false,
          options: []
        },
        hooks: {
          onInit: (field: FormlyFieldConfig) => {
            const createRequest = (variables?: any) => {
              return this.apollo
                .query<{
                  result: AcademySchoolModel[];
                }>({
                  query: gql`
                    query ReadAcademySchools($where: academy_school_bool_exp = {}) {
                      result: academy_school(order_by: { name: asc }, where: $where) {
                        id
                        name
                      }
                    }
                  `,
                  variables
                })
                .pipe(
                  map(queryResult =>
                    queryResult.data.result.map(item => ({
                      label: item.name,
                      value: item.id
                    }))
                  )
                );
            };

            if (
              field.props &&
              ((params?.initOrganisationFunction && params?.initOrganisationFunction(field)) ||
                field.model?.organisation_id)
            ) {
              let variables: any = {
                where: { organisation_id: { _eq: field.model?.organisation_id } }
              };
              if (params?.initVariablesFunction) {
                variables = params.initVariablesFunction(field);
              }
              field.props.options = createRequest(variables);
            }

            return field.options?.fieldChanges?.pipe(
              filter(
                event =>
                  event.type === 'valueChanges' &&
                  event.field.key === (params?.fieldChangesKey || 'organisation_id')
              ),
              tap(event => {
                field.formControl?.setValue(undefined);

                if (event.value && field.props) {
                  let variables: any = {
                    where: { organisation_id: { _eq: event.value } }
                  };
                  if (params?.fieldChangesVariablesFunction) {
                    variables = params.fieldChangesVariablesFunction(field);
                  }
                  field.props.options = createRequest(variables);
                }
              })
            );
          }
        }
      })
    ]);
  }

  createSchoolsFieldConfig(params?: {
    key?: string;
    required?: boolean;
    label?: string;
  }): FormlyFieldConfig {
    return FormlyUtil.createRow([
      {
        ...FormlyUtil.createMultiSelectField(params?.key || 'schools', {
          props: {
            label: params?.label || 'schools',
            required: params?.required != undefined ? params.required : true,
            valueProp: option => {
              return option.value.id;
            },
            options: []
          },
          hooks: {
            onInit: (field: FormlyFieldConfig) => {
              const createRequest = (variables?: any) => {
                return this.apollo
                  .query<{
                    result: AcademySchoolModel[];
                  }>({
                    query: gql`
                      query ReadAcademySchools($where: academy_school_bool_exp = {}) {
                        result: academy_school(order_by: { name: asc }, where: $where) {
                          id
                          name
                          organisation {
                            name
                          }
                        }
                      }
                    `,
                    variables
                  })
                  .pipe(
                    map(queryResult =>
                      queryResult.data.result.map(item => ({
                        label: item,
                        value: item
                      }))
                    )
                  );
              };

              if (field.props && !field.props['initialised']) {
                field.props['initialised'] = true;

                let variables: any = {};
                if (field.model?.organisations) {
                  variables = {
                    ...variables,
                    where: { organisation_id: { _in: field.model.organisations } }
                  };
                }

                field.props.options = createRequest(variables);
              }

              return field.options?.fieldChanges?.pipe(
                filter(
                  event => event.type === 'valueChanges' && event.field.key === 'organisations'
                ),
                tap(event => {
                  if (field.props?.['initialised']) {
                    field.formControl?.setValue(undefined);
                  }
                  if (field.props && event.value) {
                    field.props.options = createRequest({
                      where: { organisation_id: { _in: event.value } }
                    });
                  }
                })
              );
            }
          }
        }),
        type: FormlySchoolTypeComponent,
        wrappers: ['field-wrapper']
      }
    ]);
  }

  createAddressFieldConfig(params?: {
    key?: string;
    required?: boolean;
    label?: string;
    initialCount?: number;
    maxCount?: number;
    expressions?: { [p: string]: string | Observable<any> | ((field: FormlyFieldConfig) => any) };
  }) {
    return FormlyUtil.createRepeat(
      {
        key: params?.key || 'address',
        props: {
          label: params?.label || 'address',
          initialCount: params?.initialCount != undefined ? params?.initialCount : 1,
          maxCount: params?.maxCount != undefined ? params?.maxCount : 1,
          addTextValue: 'address'
        },
        expressions: params?.expressions
      },
      [
        FormlyUtil.createRow([
          FormlyUtil.createTextField('street', { props: { required: true } }),
          FormlyUtil.createTextField('street_number', { props: { required: true } })
        ]),
        FormlyUtil.createRow([
          FormlyUtil.createNumberField('postcode', {
            props: {
              required: true,
              min: 1
            }
          }),
          FormlyUtil.createTextField('place', { props: { required: true } })
        ]),
        FormlyUtil.createRow([
          FormlyUtil.createTextField('door_number'),
          FormlyUtil.createSelectField('country', {
            defaultValue: 'AT',
            props: {
              required: true,
              options: Object.keys(CoreCountryEnum).map(key => ({
                label: 'country_iso.' + key,
                value: key
              }))
            }
          })
        ]),
        FormlyUtil.createTextField('description')
      ]
    );
  }

  createEmailAddressFieldConfig(params?: {
    key?: string;
    required?: boolean;
    label?: string;
    initialCount?: number;
    maxCount?: number;
    expressions?: { [p: string]: string | Observable<any> | ((field: FormlyFieldConfig) => any) };
  }) {
    return FormlyUtil.createRepeat(
      {
        key: params?.key || 'email_address',
        props: {
          label: params?.label || 'email_address',
          initialCount: params?.initialCount,
          maxCount: params?.maxCount,
          addTextValue: 'email_address'
        },
        expressions: params?.expressions
      },
      [
        FormlyUtil.createRow([
          FormlyUtil.createTextField('email', {
            props: {
              required: true
            }
          }),
          FormlyUtil.createSelectField('email_type', {
            defaultValue: CoreEmailTypeEnum.WORK,
            props: {
              required: true,
              options: Object.values(CoreEmailTypeEnum).map(key => ({
                label: 'email_type.' + key,
                value: key
              }))
            }
          })
        ]),
        FormlyUtil.createTextField('description')
      ]
    );
  }

  createPhoneNumberFieldConfig(params?: {
    key?: string;
    required?: boolean;
    label?: string;
    initialCount?: number;
    maxCount?: number;
    expressions?: { [p: string]: string | Observable<any> | ((field: FormlyFieldConfig) => any) };
  }) {
    return FormlyUtil.createRepeat(
      {
        key: params?.key || 'phone_number',
        props: {
          label: params?.label || 'phone_number',
          initialCount: params?.initialCount,
          maxCount: params?.maxCount,
          addTextValue: 'phone_number'
        },
        expressions: params?.expressions
      },
      [
        FormlyUtil.createRow([
          FormlyUtil.createTextField('phone', {
            props: {
              required: true
            }
          }),
          FormlyUtil.createSelectField('phone_type', {
            defaultValue: CorePhoneTypeEnum.WORK,
            props: {
              required: true,
              options: Object.values(CorePhoneTypeEnum).map(key => ({
                label: 'phone_type.' + key,
                value: key
              }))
            }
          })
        ]),
        FormlyUtil.createTextField('description')
      ]
    );
  }

  createPaperTemplateFieldConfig(params?: {
    key?: string;
    required?: boolean;
    label?: string;
    readonly?: boolean;
  }): FormlyFieldConfig {
    return FormlyUtil.createRow([
      FormlyUtil.createSelectField(params?.key || 'paper_template_id', {
        props: {
          label: params?.label || 'paper_template',
          required: params?.required != undefined ? params.required : true,
          readonly: params?.readonly != undefined ? params.readonly : false,
          options: this.apollo
            .query<{
              result: PrintPaperTemplateModel[];
            }>({
              query: gql`
                query ReadPrintPaperTemplates($where: print_paper_template_bool_exp = {}) {
                  result: print_paper_template(order_by: { name: asc }, where: $where) {
                    id
                    name
                  }
                }
              `,
              variables: {
                where: {}
              }
            })
            .pipe(
              map(queryResult =>
                queryResult.data.result.map(item => ({
                  label: item.name,
                  value: item.id
                }))
              )
            )
        }
      })
    ]);
  }

  createFileUploadFieldConfig(key: string, config?: FileUploadFieldConfig): FormlyFieldConfig {
    return {
      ...config,
      className: 'w-full overflow-visible',
      props: {
        ...config?.props
      },
      expressions: {
        ...config?.expressions
      },
      key,
      type: FormlyFileUploadTypeComponent,
      wrappers: ['field-wrapper']
    };
  }

  createStatusFieldConfig(params?: {
    key?: string;
    required?: boolean;
    label?: string;
    readonly?: boolean;
  }): FormlyFieldConfig {
    return FormlyUtil.createRow([
      FormlyUtil.createSelectField(params?.key || 'status_id', {
        props: {
          label: params?.label || 'status',
          required: params?.required != undefined ? params.required : true,
          readonly: params ? params.readonly : false,
          options: this.apollo
            .query<{
              result: AcademyOrganisationModel[];
            }>({
              query: gql`
                query ReadStaffStatus {
                  result: staff_status(order_by: { name: asc }) {
                    id
                    name
                  }
                }
              `
            })
            .pipe(
              map(queryResult =>
                queryResult.data.result.map(item => ({
                  label: item.name,
                  value: item.id
                }))
              )
            )
        }
      })
    ]);
  }
}
