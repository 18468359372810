<app-file-table
  *transloco="let transloco"
  [files]="this.files()"
  [namespace]="this.namespace()"
  class="overflow-scroll">
  <ng-template libTemplate="caption">
    @if (this.progress() > 0) {
      <p-progressBar
        [showValue]="false"
        [style]="{ height: '4px' }"
        [value]="this.progress()"
        class="absolute left-0 top-0 w-full"></p-progressBar>
    }
    <div class="flex gap-2">
      <!-- File input for individual files -->
      <input
        #fileUploader
        (change)="this.uploadFile(fileUploader.files); fileUploader.value = ''"
        [accept]="this.accept()"
        [multiple]="this.multiple()"
        [size]="this.size() || this.sizeLimit"
        hidden
        type="file" />

      <!-- Folder input -->
      <input
        #folderUploader
        (change)="this.uploadFile(folderUploader.files); folderUploader.value = ''"
        [accept]="this.accept()"
        [multiple]="this.multiple()"
        [size]="this.size() || this.sizeLimit"
        directory
        hidden
        type="file"
        webkitdirectory />

      @if (this.canUpload() && !this.disabled() && !this.readonly()) {
        <p-button
          (click)="fileUploader.click()"
          outlined
          icon="pi pi-upload"
          label="{{ transloco(this.multiple() ? 'upload_files' : 'upload') }}"></p-button>

        @if (this.multiple()) {
          <p-button
            icon="pi pi-folder-plus"
            (click)="folderUploader.click()"
            outlined
            label="{{ transloco('upload_folder') }}"></p-button>
        }
      }
    </div>

    @if (this.progress() > 0) {
      <p-progressBar
        [showValue]="false"
        [style]="{ height: '4px' }"
        mode="indeterminate"
        class="absolute bottom-0 left-0 w-full"></p-progressBar>
    } @else {
      <small class="mt-4 block">Max. 15MB</small>
    }
  </ng-template>

  <ng-template let-file libTemplate="actions">
    @if (!this.disabled() && !this.readonly()) {
      <p-button
        (click)="this.removeFile(file.id)"
        [pTooltip]="transloco('delete')"
        severity="danger"
        text
        icon="pi pi-trash"
        tooltipPosition="top"></p-button>
    }
  </ng-template>
</app-file-table>
