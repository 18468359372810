import { Component } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';
import { AsyncPipe, NgClass } from '@angular/common';
import { FormlySelectModule } from '@ngx-formly/core/select';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { TranslocoDirective } from '@jsverse/transloco';
import { AutoCompleteTypeComponent } from '~ngx-shared/formly/auto-complete-type/auto-complete-type.component';
import { DatePipe } from '~ngx-shared/pipes';

@Component({
  selector: 'app-formly-person-search-select',
  imports: [
    AutoCompleteModule,
    ReactiveFormsModule,
    FormlyModule,
    NgClass,
    FormlySelectModule,
    AsyncPipe,
    TranslocoDirective,
    DatePipe
  ],
  templateUrl: './formly-person-search-type.component.html',
  styleUrl: './formly-person-search-type.component.scss'
})
export class FormlyPersonSearchTypeComponent extends AutoCompleteTypeComponent {
  protected readonly length = length;
}
