import { Component, Type } from '@angular/core';
import { ProgressBarModule } from 'primeng/progressbar';
import { TooltipModule } from 'primeng/tooltip';
import { FieldType, FieldTypeConfig, FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { NgClass } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { FileUploadComponent } from '~madrasa/core/components/file-upload/file-upload.component';
import { FieldWrapperProps } from '~ngx-shared/formly/field-wrapper/field-wrapper.component';

interface FileUploadProps extends FieldWrapperProps {
  namespace: string;
  maxFiles?: number;
  multiple?: boolean;
  accept?: string;
  size?: number;
}

export interface FileUploadFieldConfig extends FormlyFieldConfig<FileUploadProps> {
  type?: 'file-upload' | Type<FormlyFileUploadTypeComponent>;
}

@Component({
  selector: 'app-formly-file-upload-type',
  imports: [
    ProgressBarModule,
    TooltipModule,
    NgClass,
    FileUploadComponent,
    FormlyModule,
    ReactiveFormsModule
  ],
  templateUrl: './formly-file-upload-type.component.html',
  styleUrl: './formly-file-upload-type.component.scss'
})
export class FormlyFileUploadTypeComponent extends FieldType<FieldTypeConfig<FileUploadProps>> {}
