import { Component, computed, forwardRef, inject, input, output, signal } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { ProgressBarModule } from 'primeng/progressbar';
import { TranslocoDirective } from '@jsverse/transloco';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Apollo } from 'apollo-angular';
import { TooltipModule } from 'primeng/tooltip';
import { HttpEvent, HttpEventType, HttpResponse } from '@angular/common/http';
import { finalize, forkJoin, Observable, tap } from 'rxjs';
import { TemplateDirective } from '~ngx-shared/directives';
import { FileTableComponent } from '~madrasa/core/components/file-table/file-table.component';
import { FileService } from '~madrasa/services/file.service';
import { FileModel } from '~madrasa/models';

@Component({
  selector: 'app-file-upload',
  imports: [
    ButtonModule,
    FileTableComponent,
    ProgressBarModule,
    TemplateDirective,
    TranslocoDirective,
    TooltipModule
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => FileUploadComponent)
    }
  ],
  templateUrl: './file-upload.component.html',
  styleUrl: './file-upload.component.scss'
})
export class FileUploadComponent implements ControlValueAccessor {
  readonly apollo = inject(Apollo);
  readonly fileService = inject(FileService);

  readonly namespace = input.required<string>();
  readonly maxFiles = input<number | undefined>();
  readonly multiple = input<boolean | undefined>();
  readonly accept = input<string | undefined>();
  readonly size = input<number | undefined>();
  readonly readonly = input<boolean | undefined>();

  readonly isUploadingChange = output<boolean>();
  readonly files = signal<FileModel[]>([]);
  readonly progress = signal<number>(0);
  readonly disabled = signal<boolean>(false);

  readonly canUpload = computed(() => {
    const files = this.files();
    const maxFiles = this.maxFiles();
    return !maxFiles || files.length < maxFiles;
  });

  // Default size limit is 15MB
  readonly sizeLimit = 15 * 1024 * 1024;

  private _uploads: {
    uploadProgress: number;
    downloadProgress: number;
    name?: string;
    size?: number;
  }[] = [];
  private _touched = false;

  async uploadFile(files: FileList | null): Promise<void> {
    this.markAsTouched();
    const requests: Observable<HttpEvent<string>>[] = [];

    if (!files) {
      return;
    }

    this._uploads = [...Array(files.length)].map(_ => ({ uploadProgress: 0, downloadProgress: 0 }));
    for (let i = 0; i < files.length; i++) {
      const file = files.item(i);

      if (!file) {
        continue;
      }

      requests.push(
        this.fileService.uploadFile$(file, this.namespace()).pipe(
          tap(event => {
            this._uploads[i].name = file.name;
            this._uploads[i].size = file.size;
            if (event.type === HttpEventType.UploadProgress) {
              this._uploads[i].uploadProgress = event.loaded / event.total!;
            } else if (event.type === HttpEventType.DownloadProgress) {
              this._uploads[i].downloadProgress = event.loaded / event.total!;
            }
            this.calculateProgress();
          })
        )
      );
    }

    this.isUploadingChange.emit(true);

    this.disabled.set(true);

    forkJoin(requests)
      .pipe(
        finalize(() => {
          const ptr = setTimeout(() => {
            this._uploads = [];
            this.progress.set(0);
            clearTimeout(ptr);
            this.disabled.set(false);
          }, 250);
          this.isUploadingChange.emit(false);
        })
      )
      .subscribe(result => {
        const ids = (result as HttpResponse<string>[]).map(item => item.body!);
        // Get name and size of the uploaded files
        const newFiles: FileModel[] = this._uploads.map((upload, index) => ({
          id: ids[index],
          name: upload.name,
          size: upload.size,
          cannotShow: true
        }));

        // Merge the new files with the existing ones
        this.files.set([...this.files(), ...newFiles]);
        this.changeValue();
      });
  }

  removeFile(id: string): void {
    let files = this.files();
    const index = files.findIndex((file: FileModel) => file.id === id);
    if (index !== -1) {
      files = [...files.slice(0, index), ...files.slice(index + 1)];
      this.files.set(files);
      this.changeValue();
    }
  }

  calculateProgress(): void {
    const uploadLength = this._uploads.length;
    if (uploadLength === 0) {
      this.progress.set(0);
      return;
    }
    let progress = 0;
    // Upload makes 90% of the progress bar and download 10%
    // so if there are 3 items, the upload of a single item makes 30% and its download 3.3%
    // Lets say only the first item is finished and the other 2 items aren't even uploaded yet,
    // the progress in total would be 33,3%
    for (const upload of this._uploads) {
      progress +=
        upload.uploadProgress * (90 / uploadLength) + upload.downloadProgress * (10 / uploadLength);
    }
    this.progress.set(progress);
  }

  changeValue() {
    const files = this.files()
      .map(file => file.id)
      .filter((item): item is string => !!item);

    this.onChange(files);
    this.markAsTouched();
  }

  // Form methods

  markAsTouched() {
    if (!this._touched) {
      this.onTouched();
      this._touched = true;
    }
  }
  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState(disabled: boolean) {
    this.disabled.set(disabled);
  }

  writeValue(ids: string[]): void {
    if (ids?.length) {
      this.fileService.getFileMetas$(ids, this.namespace()).subscribe(result => {
        this.files.set(result);
      });
    }
  }

  private onChange = (ids: string[]) => {};

  private onTouched = () => {};
}
