<div *transloco="let transloco" class="flex items-center gap-2 overflow-x-auto">
  <p-autoComplete
    (completeMethod)="
      this.props.completeMethod ? this.props.completeMethod($event, this.field) : null
    "
    [forceSelection]="this.props.forceSelection || true"
    [formControl]="this.formControl"
    [formlyAttributes]="this.field"
    [multiple]="this.props.multiple"
    [ngClass]="{
      'ng-dirty ng-invalid p-invalid': this.showError
    }"
    [optionLabel]="this.props.labelProp"
    [placeholder]="transloco(this.props.placeholder || '') || '&nbsp;'"
    [readonly]="this.props.readonly"
    [showClear]="!this.props.required"
    [suggestions]="(this.props.options | formlySelectOptions: this.field | async)!"
    [virtualScrollItemSize]="this.props.virtualScrollSize"
    [virtualScroll]="this.props.virtualScroll"
    appendTo="body"
    class="w-full"
    fluid>
    <ng-template let-item pTemplate="item">
      <span>{{ this.getValueAsString(item, 'label') }},</span>
      <span class="ml-2 text-stone-400">Id: {{ item?.value?.value?.person_id }},</span>
      <span class="ml-2 text-stone-400"
        >{{ transloco('date_of_birth') }}: {{ item?.value?.value?.date_of_birth | libDate }}</span
      >
    </ng-template>
    <ng-template let-selectedItem pTemplate="selectedItem">
      <span>
        {{ transloco(this.getValueAsString(selectedItem, 'label')) }}
      </span>
    </ng-template>
  </p-autoComplete>

  <p-button
    (click)="this.props.clickMethod ? this.props.clickMethod($event, this.field) : null"
    [disabled]="!this.formControl.value"
    [severity]="this.props.severity || 'success'"
    [class]="this.props.clickClass"
    [label]="transloco(this.props.clickLabel || 'add')"
    [icon]="this.props.clickIcon || 'pi pi-plus'">
  </p-button>
</div>
