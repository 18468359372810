import { inject, Injectable } from '@angular/core';
import { select, setProp } from '@ngneat/elf';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Apollo, gql } from 'apollo-angular';
import { combineLatest, map } from 'rxjs';
import { toSignal } from '@angular/core/rxjs-interop';
import { cloneDeep } from 'lodash-es';
import { AuthenticationService, AuthorizationService } from '~ngx-shared/authentication';
import { StoreService } from '~ngx-shared/services/store.service';
import { AccessUserModel, FilePersonMediaTypeEnum } from '../models';
import { FileService } from '~madrasa/services/file.service';

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class UserService {
  private apollo = inject(Apollo);
  private fileService = inject(FileService);
  private storeService = inject(StoreService);
  private authenticationService = inject(AuthenticationService);
  private authorizationService = inject(AuthorizationService);

  userStore = this.storeService.createStore<{ user: AccessUserModel }>({
    name: 'user',
    initial: undefined,
    persist: { storage: 'session' }
  });

  user$ = this.userStore.pipe(select(state => state.user));

  readonly user = toSignal(this.user$);

  private _intervalId: any;

  constructor() {
    combineLatest([this.authenticationService.authModel$, this.authorizationService.currentRole$])
      .pipe(untilDestroyed(this))
      .subscribe(result => {
        const [authModel, currentRole] = result;
        if (authModel.isLoggedOut) {
          this._stopLastSeen();
        } else if (
          authModel.isAuthenticated &&
          authModel.roles &&
          authModel.roles.length &&
          authModel.claims?.['userId'] &&
          currentRole &&
          !this.user()
        ) {
          this.apollo
            .query<{ result: AccessUserModel }>({
              query: gql`
                  query ReadAccessUserById($id: uuid!) {
                    result: access_user_by_pk(id: $id) {
                      id
                      person_id
                      name
                      email
                      current_person_data {
                        person_id
                        academic_degree_prefix
                        academic_degree_suffix
                        first_name
                        last_name

                        person_media(
                          limit: 1
                          order_by: { created_at: desc }
                          where: { person_media_type: { _eq: "${FilePersonMediaTypeEnum.PROFILE_PICTURE}" } }
                        ) {
                          id
                        }
                      }
                    }
                  }
                `,
              variables: {
                id: authModel.claims['userId']
              }
            })
            .pipe(map(queryResult => queryResult.data?.result))
            .subscribe(result => {
              if (result) {
                const user = cloneDeep(result);

                const id = user?.current_person_data?.person_media?.[0]?.id;
                if (id && user.current_person_data) {
                  user.current_person_data['profile_picture_url'] = this.fileService.getFileUrl(
                    id,
                    'person_media'
                  );
                }

                this.userStore.update(setProp('user', user));
                if (!this._intervalId) {
                  this._intervalId = setInterval(() => this._updateLastSeen(), 120000);
                }
              }
            });
        }
      });
  }

  init() {}

  private _updateLastSeen(): void {
    this.apollo
      .mutate({
        mutation: gql`
          mutation UpdateUserLastSeen($userId: uuid!) {
            update_access_user_by_pk(_set: { last_seen_at: "now()" }, pk_columns: { id: $userId }) {
              __typename
            }
          }
        `,
        variables: {
          userId: this.userStore.value?.user?.id
        }
      })
      .subscribe();
  }

  private _stopLastSeen(): void {
    if (this._intervalId) {
      clearInterval(this._intervalId);
    }
  }
}
