<div *transloco="let transloco" class="flex">
  <p-multiSelect
    (onChange)="this.props.change && this.props.change(field, $event)"
    [formControl]="this.formControl"
    [formlyAttributes]="this.field"
    [ngClass]="{
      'ng-dirty ng-invalid p-invalid': this.showError
    }"
    [optionGroupChildren]="this.props.groupChildrenProp || 'group'"
    [optionGroupLabel]="this.props.groupLabelProp || 'label'"
    [options]="(this.props.options | formlySelectOptions: this.field | async)!"
    [placeholder]="transloco(this.props.placeholder || '') || '&nbsp;'"
    [readonly]="this.props.readonly"
    [scrollHeight]="this.props.scrollHeight || '500px'"
    [showClear]="!this.props.required"
    [optionLabel]="this.props.optionLabel || 'label'"
    [optionValue]="this.props.optionValue || 'value'"
    [virtualScrollItemSize]="this.props.virtualScrollSize"
    [virtualScroll]="this.props.virtualScroll"
    appendTo="body"
    class="w-full"
    fluid>
    <ng-template let-item pTemplate="item">
      <span>{{ item['label']?.name }},</span>
      <span class="ml-2 text-stone-400">{{ item['label']?.organisation?.name }}</span>
    </ng-template>
    <ng-template let-selectedItems pTemplate="selectedItems">
      <div class="inline-grid">
        @if (selectedItems?.length) {
          <span class="truncate">
            @for (selectedItem of selectedItems; track selectedItem; let index = $index) {
              {{ selectedItem['label']?.name }}{{ selectedItems.length === index + 1 ? '' : ',' }}
            }
          </span>
        }
      </div>
    </ng-template>
  </p-multiSelect>
</div>
